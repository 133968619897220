
import { defineComponent } from "vue";
import store from "@/store/index";
import { Page, PageType } from "@/store/pages/types";
import PagePath from "@/components/layout/navbar/PagePath.vue";

export default defineComponent({
  name: "EmptyWorkspace",
  components: {
    PagePath,
  },
  computed: {
    _name: function () {
      return store.getters["workspace/getName"];
    },
    _icon: function () {
      return store.getters["workspace/getIcon"];
    },
  },
  methods: {
    createPage: function () {
      const page = Page.create("", "", "New Page", "");
      page.name = "New page";
      store.dispatch("pages/create", page).then((page) => {
        this.$router.push({
          name: "Page",
          params: {
            workspaceId: this.$route.params.workspaceId,
            pageId: page.id,
          },
        });
      });
    },
  },
});
